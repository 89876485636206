import React, {useContext} from 'react'
import { GlobalDataContext } from "../../context/context";
import CountUp from 'react-countup';



const CounterUp = ({image}) => {

    const { rpdata } = useContext(GlobalDataContext);


   /*  const counterInfo = [
        {
            title: rpdata?.dbPrincipal?.exprYears.length > 1 ? 'Years Of Experience' : 'Best Service',
            number: rpdata?.dbPrincipal?.exprYears.length > 1 ? rpdata?.dbPrincipal?.exprYears : '100',
            simbolo: rpdata?.dbPrincipal?.exprYears.length > 1 ? '+' : '%',
        },
        {
            title: 'Professionals',
            number: 100,
            simbolo: '%',
        },
        {
            title: rpdata?.dbPrincipal?.miles.length > 1 ? `Miles Around ${rpdata?.dbPrincipal?.location[0]?.address}` : 'Best Service' ,
            number: rpdata?.dbPrincipal?.miles.length > 1 ? rpdata?.dbPrincipal?.miles : '100',
            simbolo: rpdata?.dbPrincipal?.miles.length > 1 ? '+' : '%',
        },
        {
            title: 'On Time',
            number: 100,
            simbolo: '%',
        },
    ] */

  
   



    return (
      <div
        className="bgCountent"
        style={{
          backgroundImage: `url("${image ? image : rpdata?.stock?.[1]}")`,
        }}
      >
        <h2 className="text-center py-4 relative text-white">
          {rpdata?.dbSlogan?.[4].slogan}
        </h2>
        <div className="w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          
          <div className="bgColor1 text-center p-3 relative">
            <CountUp
              end={rpdata?.dbPrincipal?.exprYears}
              duration={5}
              className="text-white text-[69px]"
            />
            <span className="text-white text-[69px]">+</span>
            <p className="text-white text-[18px]">Years Of Experience</p>
          </div>

          <div className="bgColor2 text-center p-3 relative">
            <CountUp
              end={100}
              duration={5}
              className="text-black text-[69px]"
            />
            <span className="text-black text-[69px]">%</span>
            <p className="text-black text-[18px]">Professionals</p>
          </div>

          <div className="bgColor1 text-center p-3 relative">
            <CountUp
              end={rpdata?.dbPrincipal?.miles}
              duration={5}
              className="text-white text-[69px]"
            />
            <span className="text-white text-[69px]">+</span>
            <p className="text-white text-[18px]">Miles Around {rpdata?.dbPrincipal?.location[0]?.address}</p>
          </div>

          <div className="bgColor2 text-center p-3 relative">
            <CountUp
              end={100}
              duration={5}
              className="text-black text-[69px]"
            />
            <span className="text-black text-[69px]">%</span>
            <p className="text-black text-[18px]">On Time</p>
          </div>
          

        {/*   {counterInfo.map((items, index) => {
            const colorContent = index % 2 === 0 ? "bgColor1" : "bgColor2";
            return (
              <div
                key={index}
                className={`text-center p-3 relative ${colorContent}`}
              >
                <div>
                  <CountUp
                    end={items.number}
                    duration={5}
                    className="text-white text-[69px]"
                  />
                  <span className="text-white text-[69px]">
                    {items.simbolo}
                  </span>
                </div>
                <p className="text-white text-[18px]">{items.title}</p>
              </div>
            );
          })}
 */}
          <div></div>
        </div>
      </div>
    );
}
export default CounterUp
